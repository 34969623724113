<template>
    <v-container fluid>
        <v-card>
            <v-card-title>
                <v-dialog v-if="!$store.getters.vendorId" v-model="dialog" fullscreen max-width="500px">
                    <template v-slot:activator="{ on }">
                        <v-btn color="primary" dark class="mb-2" v-on="on">{{ trans('buttons.offer.newItem') }}</v-btn>
                    </template>
                    <offer-form v-if="dialogLoaded" :item-id="editedItem" v-on:saved="onSave" v-on:close="close"></offer-form>
                </v-dialog>

                <v-spacer></v-spacer>
                <v-icon
                    class="pull-right"
                    color="primary"
                    @click="filterShow = filterShow === 0 ? null : 0"
                >mdi-filter</v-icon>
                <v-expansion-panels
                    v-model="filterShow"
                    flat
                    tile
                >
                    <v-expansion-panel>
                        <v-expansion-panel-content class="pt-4">
                            <v-row>
                                <v-col style="min-width: 200px;">
                                    <v-text-field
                                        v-model="filters.label"
                                        :label="trans('fields.common.label')"
                                    ></v-text-field>
                                </v-col>
                                <v-col style="min-width: 200px;">
                                    <v-select
                                        :items="options.offerTypes"
                                        :label="trans('fields.common.offerTypes')"
                                        :multiple="true"
                                        v-model="filters.type_id"
                                    ></v-select>
                                </v-col>
                                <v-col style="min-width: 200px;">
                                    <v-select
                                        :items="options.offerScopes"
                                        :label="trans('fields.common.scope')"
                                        :multiple="true"
                                        v-model="filters.scope"
                                    ></v-select>
                                </v-col>
                                <v-col style="min-width: 200px;">
                                    <v-select
                                        :items="options.countries"
                                        :label="trans('fields.common.countries')"
                                        :multiple="true"
                                        v-model="filters.country_id"
                                    ></v-select>
                                </v-col>
                                <v-col style="min-width: 200px;">
                                    <v-select
                                        :items="options.languages"
                                        :label="trans('fields.common.languages')"
                                        :multiple="true"
                                        v-model="filters.language_id"
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-btn
                                    class="ma-2"
                                    color="primary"
                                    outlined
                                    @click="applyFilters"
                                >{{ trans('buttons.common.apply') }}</v-btn>
                                <v-btn
                                    class="ma-2"
                                    color="orange"
                                    outlined
                                    @click="clearFilters"
                                >{{ trans('buttons.common.clear') }}</v-btn>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card-title>
        </v-card>

        <v-row v-if="!options.offerTypes">
            <v-col cols="12">
                <v-skeleton-loader
                    class="mx-auto"
                    max-width="100%"
                    type="card"
                ></v-skeleton-loader>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col cols="12" v-for="item in paginationItems.data" :key="item.id">
                <offer-table-item
                        :item="item"
                        :key="item.id"
                        :options="options"
                        v-on:createOfferAppearance="createOfferAppearance($event)"
                        v-on:editItem="editItem($event)"
                        v-on:deleteDialog="deleteDialogShow($event)"
                        v-on:offerDescriptionSaved="initialize"
                ></offer-table-item>
            </v-col>
        </v-row>
        <v-row v-if="paginationItems && paginationItems.data && paginationItems.data.length">
            <v-col cols="12">
                <v-pagination
                    v-model="paginationParams.page"
                    :length="pagination.pageStop"
                    :page="pagination.page"
                    :total-visible="12"
                    :disabled="loading"
                    @input="changePage"
                ></v-pagination>
            </v-col>
        </v-row>

        <v-dialog v-model="offerAppearanceDialog" max-width="800px">
            <template v-if="$store.getters.vendorId">
                <add-offer-appearance-vendor-lock-form
                        v-if="offerAppearanceDialogLoaded"
                        :offer_id="offerAppearanceItem ? offerAppearanceItem.id : null"
                        @saved="offerAppearanceSaved"
                        @close="offerAppearanceDialogClosed"
                />
            </template>
            <template v-else>
                <add-offer-appearance-form
                        v-if="offerAppearanceDialogLoaded"
                        :offer_id="offerAppearanceItem ? offerAppearanceItem.id : null"
                        @saved="offerAppearanceSaved"
                        @close="offerAppearanceDialogClosed"
                />
            </template>


        </v-dialog>

        <delete-dialog
            :dialogTrigger="deleteDialog"
            :item-id="deleteItemId"
            :delete-route="deleteRoute"
            @closed="deleteDialogClosed"
            @deleted="deleteDialogDeleted"
        >
            <template v-slot:title>
                {{ trans('pages.offer.deleteDialogTitle') }}
            </template>
            <template v-slot:text>
                {{ trans('pages.offer.deleteDialogText', {id: deleteItemId}) }}
            </template>
        </delete-dialog>
    </v-container>


</template>

<script>
    import tableWithPagination from "@/mixins/tableWithPagination";
    import DeleteDialog from "@/components/commonComponents/DeleteDialog";
    import deleteDialog from "@/mixins/deleteDialog";
    import tableFilters from "@/mixins/tableFilters";
    import OfferTableItem from "@/components/user/offer/OfferTableItem";

    export default {
        name: "OfferTable",
        components: {
            OfferTableItem,
            OfferForm: () => import('@/components/user/offer/OfferForm'),
            AddOfferAppearanceForm: () => import('@/components/user/offerAppearance/AddOfferAppearanceForm'),
            AddOfferAppearanceVendorLockForm: () => import('@/components/user/offerAppearance/AddOfferAppearanceVendorLockForm'),
            DeleteDialog,
        },
        mixins: [tableWithPagination, deleteDialog, tableFilters],
        data() {
            return {
                dialog: false,
                dialogLoaded: false,
                offers: {},
                headers: [
                    { text: "id", sortable: false, value: "id" },
                    { text: this.trans("fields.common.label"), value: "label", sortable: true },
                    { text: this.trans("fields.common.offerType"), value: "offerType", sortable: false },
                    { text: this.trans("fields.common.language"), value: "language", sortable: false },
                    { text: this.trans("fields.common.countries"), value: "countries", sortable: false },
                    { text: this.trans("fields.common.created_at_m"), value: "created_at", sortable: true },
                    { text: this.trans("fields.common.actions"), value: "actions", sortable: false },
                ],
                paginationParams: {
                    page: 1,
                    per_page: 10,
                },
                url: this.route('user.offers.index'),
                deleteRoute: 'user.offers.delete',

                editedItem: null,

                filters: {
                    label: '',
                    country_id: null,
                    language_id: null,
                    type_id: null,
                },
                filtersDefault: {
                    label: '',
                    country_id: null,
                    language_id: null,
                    type_id: null,
                },

                offerAppearanceItem: null,
                offerAppearanceDialog: false,
                offerAppearanceDialogLoaded: false,

            }
        },
        created() {
            this.editedItem = null;
            this.loadOptions({languages: true, offerScopes: true, offerTypes: true, countries: true});
        },
        watch: {
            dialog(val) {
                if (val){
                    this.dialogLoaded = true
                } else {
                    this.close();
                }
            },
        },
        methods: {
            onSave(){
                this.close();
                this.initialize();
            },
            editItem(item) {
                this.editedItem = item.id;
                this.dialog = true;
                this.dialogLoaded = true;
            },
            close() {
                this.dialog = false;
                setTimeout(() => {
                    this.editedItem = null;
                    this.dialogLoaded = false;
                }, 300);
            },
            deleted(){
                this.initialize();
            },
            createOfferAppearance(item){
                this.offerAppearanceItem = item;
                this.offerAppearanceDialogLoaded = true;
                this.offerAppearanceDialog = true;
            },
            offerAppearanceDialogClosed(){
                this.offerAppearanceDialog = false;
                setTimeout(() => {
                    this.offerAppearanceDialogLoaded = false;
                    this.offerAppearanceItem = null;
                }, 300);
            },
            offerAppearanceSaved(){
                this.offerAppearanceDialogClosed();
            },

        },
        computed: {
            paginationItems: {
                get(){
                    return this.offers;
                },
                set(v){
                    this.offers = v;
                }
            },
        }
    }
</script>

