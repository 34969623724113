<template>
    <v-card>
        <v-container fluid class="pt-0 pb-0">
            <v-row justify="space-between">
                <v-col
                        cols="12" sm="6" md="4" lg="2"
                        class="pt-0 pb-0"
                >
                    <v-row>
                        <v-col
                                class="pt-0 pl-0"
                        >
                            <v-chip label color="primary">id: {{ item.id }}</v-chip>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                                class="pa-0"
                        >
                            <v-skeleton-loader
                                    v-if="!item.logoUrl"
                                    width="100%"
                                    boilerplate
                                    type="image"
                            ></v-skeleton-loader>
                            <v-img
                                    v-else
                                    max-width="100%"
                                    max-height="250"
                                    contain
                                    :src="item.logoUrl"
                            >
                                <template v-slot:placeholder>
                                    <v-row
                                            class="fill-height ma-0"
                                            align="center"
                                            justify="center"
                                    >
                                        <v-progress-circular indeterminate color="grey"></v-progress-circular>
                                    </v-row>
                                </template>
                            </v-img>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col
                        cols="12" sm="6" md="8" lg="3"
                >
                    <v-row>
                        <v-col cols="12">
                            {{ offerLabel }}
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="6">
                            <span class="text--disabled">{{ trans('fields.common.language') }}:</span> {{ options.languagesKeyed[item.language_id] }}
                        </v-col>
                        <v-col cols="6">
                            <span class="text--disabled">{{ trans('fields.common.countries') }}:</span>
                            <template v-for="(countryId, index) in item.countriesArr"> {{ options.countriesKeyed[countryId] }}<template v-if="index < item.countriesArr.length -1">, </template> </template>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <span class="text--disabled">{{ trans('fields.common.created_at_m') }}:</span> {{ item.created_at | dateFormat('YYYY-MM-DD') }}
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="pt-1 pb-1">
                            <v-btn
                                    @click="$emit('createOfferAppearance', item)"
                                    color="primary"
                                    small
                                    outlined
                            >
                                <span class="d-none d-md-block">{{ trans('buttons.offer.addToLanding') }}</span>
                                <span class="d-block d-md-none">{{ trans('buttons.offer.addToLandingShort') }}</span>
                                <v-icon
                                        small
                                        right
                                >mdi-plus</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col
                                cols="12"
                                class="pt-1 pb-1"
                                v-if="item.user_id && item.scope == 2"
                        >
                            <v-btn
                                    @click="$emit('editItem', item)"
                                    color="primary"
                                    small
                                    outlined
                            >
                                {{ trans('buttons.common.edit') }}
                                <v-icon
                                        small right
                                >mdi-pencil</v-icon>

                            </v-btn>
                        </v-col>
                        <v-col
                                cols="12"
                                class="pt-1 pb-1"
                                v-if="item.user_id && item.scope == 2"
                        >
                            <v-btn
                                    @click="$emit('deleteDialog', item.id)"
                                    color="red"
                                    small
                                    outlined
                            >
                                {{ trans('buttons.common.delete') }}
                                <v-icon
                                        small right
                                >mdi-delete</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col
                        cols="12" sm="12" md="12" lg="7"
                >
                    <v-row>
                        <v-col>
                            <v-select
                                v-model="curDescription"
                                :label="trans('fields.common.offerDescription')"
                                :items="item.offerDescriptionsOptions"
                                dense
                            >
                                <template v-slot:append-outer>
                                    <v-btn
                                            x-small
                                            color="primary"
                                            outlined
                                            class="hidden-xs-only mr-2"
                                            @click="offerDescriptionDialogShow(null)"
                                    >
                                        <span class="hidden-sm-and-down">{{ trans('buttons.offerDescription.newItem') }}</span>
                                        <span class="hidden-md-and-up">{{ trans('buttons.offerDescription.newItemShort') }}</span>
                                    </v-btn>
                                    <v-btn
                                            x-small
                                            color="primary"
                                            outlined
                                            :disabled="!curDescription"
                                            class="hidden-xs-only"
                                            @click="offerDescriptionDialogShow(curDescription)"
                                    >
                                        <span class="hidden-sm-and-down">{{ trans('buttons.offerDescription.editItem') }}</span>
                                        <span class="hidden-md-and-up">{{ trans('buttons.offerDescription.editItemShort') }}</span>
                                    </v-btn>
                                    <v-icon
                                            v-if="!curDescription"
                                            color="primary"
                                            class="hidden-sm-and-up"
                                            @click="offerDescriptionDialogShow(null)"
                                    >mdi-plus</v-icon>
                                    <v-icon
                                            v-else
                                            color="primary"
                                            class="hidden-sm-and-up"
                                            @click="offerDescriptionDialogShow(curDescription)"
                                    >mdi-pencil</v-icon>
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <template v-for="i in 5">
                                <v-chip v-if="fields['advantage' + i].value" :key="item.id + '-' + i" label color="blue lighten-4" class="ma-1">
                                    {{ fields['advantage' + i].value }}
                                </v-chip>
                            </template>
                        </v-col>
                    </v-row>
                    <v-row>
                        <template v-for="fieldName in ['age', 'percentRate', 'period', 'sum', 'cashBack', 'serviceFee', 'noPercentPeriod', 'bail', 'docs', 'offices']">
                            <v-col
                                    cols="12" md="6" :lg="fields[fieldName].value ? 6 : 3" :xl="fields[fieldName].value ? 4 : 3"
                                    v-if="fields[fieldName]"
                                    :key="item.id + '-' + fieldName"
                                    style="font-size: 0.8em"
                            >
                                <span class="text--secondary">{{ fields[fieldName].label }}:</span>
                                <template v-if="fields[fieldName].text && fields[fieldName].text.value !== null">
                                    {{ fields[fieldName].text.value }}
                                </template>
                                <template v-else-if="fields[fieldName].value">
                                    {{ fields[fieldName].value }}
                                </template>
                                <template v-else>
                                    <template v-if="fields[fieldName].from && fields[fieldName].from.value !== null">
                                        {{ fields[fieldName].from.label }} {{ fields[fieldName].from.prefixValue }} {{ fields[fieldName].from.value }} {{ fields[fieldName].from.suffixValue }}
                                    </template>
                                    <template v-if="fields[fieldName].to && fields[fieldName].to.value !== null">
                                        {{ fields[fieldName].to.label }} {{ fields[fieldName].to.prefixValue }} {{ fields[fieldName].to.value }} {{ fields[fieldName].to.suffixValue }}
                                    </template>
                                    <template v-if="fields[fieldName].period && fields[fieldName].period !== null">
                                        {{ fields[fieldName].period.label }}
                                    </template>
                                </template>
                            </v-col>
                        </template>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
        <v-dialog v-model="offerDescriptionDialog" :fullscreen="$vuetify.breakpoint.xsOnly" max-width="800px" v-on:click:outside="offerDescriptionDialogClose">
            <offer-description-form
                    dense
                    v-if="offerDescriptionDialogLoaded"
                    :offer_id="item.id"
                    :item-id="offerDescriptionDialogItemId"
                    v-on:saved="offerDescriptionDialogOnSave"
                    v-on:close="offerDescriptionDialogClose"
            />
        </v-dialog>
    </v-card>
</template>

<script>
    import OfferDescriptionForm from "@/components/user/offerDescription/OfferDescriptionForm";
    export default {
        name: "OfferTableItem",
        components: {OfferDescriptionForm},
        props: {
            item: {
                type: Object,
                required: true,
            },
            options: {
                type: Object,
                required: true,
            }
        },
        data(){
            return {
                curDescription: null,
                offerDescriptionDialog: false,
                offerDescriptionDialogItemId: null,
                offerDescriptionDialogLoaded: false,
            }
        },
        computed:{
            fields(){
                if (this.curDescription === null || !this.item.offerDescriptionsFieldsFilled[this.curDescription]){
                    return this.item.fieldsFilled;
                } else {
                    return this.item.offerDescriptionsFieldsFilled[this.curDescription];
                }
            },
            offerLabel(){
                if (this.curDescription === null || !this.item.offerDescriptionsFieldsFilled[this.curDescription]){
                    return this.item.label;
                } else {
                    return this.item.offerDescriptionsLabels[this.curDescription];
                }
            }
        },
        methods: {
            offerDescriptionDialogShow(id){
                this.offerDescriptionDialogItemId = id;
                this.offerDescriptionDialogLoaded = true;
                this.offerDescriptionDialog = true;
            },
            offerDescriptionDialogClose(){
                this.offerDescriptionDialogItemId = null;
                this.offerDescriptionDialog = false;
                setTimeout(() => {
                    this.offerDescriptionDialogLoaded = false;
                }, 300);
            },
            offerDescriptionDialogOnSave(id){
                this.offerDescriptionDialogItemId = id;
                this.offerDescriptionDialogClose();
                this.curDescription = id;
                this.$emit('offerDescriptionSaved')
            },
        }
    }
</script>

<style scoped>

</style>